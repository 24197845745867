<template>
  <v-dialog v-if="dialog" v-model="dialog" fullscreen style="z-index: 10001">
    <v-card color="rgba(0,0,0,0.85)" dark tile>
      <v-card-title>
        <v-btn class="mr-2" icon @click="dialog = false">
          <v-icon>arrow_back</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="py-0">
        <div :class="$vuetify.breakpoint.smAndUp ? 'v-overlay': ''">
          <img-from-zulu :url="field.url"/>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ImgFromZulu from '@/components/map/helpers/ImgFromZulu'

export default {
  name: 'PhotoDialog',
  components: { ImgFromZulu },
  props: ['feature'],
  data: () => ({
    dialog: false,
    field: null
  }),
  methods: {
    async open (field, feature) {
      this.dialog = true
      this.field = field
    }
  }
}
</script>
