<template>
  <v-textarea
    :label="header.fieldName"
    :readonly="readonly"
    :value="value"
    auto-grow
    class="mb-3"
    dense
    hide-details
    outlined
    row-height="1"
    @input="val => $emit('update:value', val)"
  >
    <template v-slot:append v-if="header.commentField">
      <v-icon>mdi-message-reply-text</v-icon>
    </template>
  </v-textarea>
</template>

<script>
export default {
  name: 'StringEtItem',
  props: {
    value: null,
    header: Object,
    editable: Boolean
  },
  computed: {
    readonly () {
      return (this.header.isPrimaryTableKey && !this.header.useInInsert)
        || this.header.isComputedField
        || !this.editable
    }
  }
}
</script>
