<template>
  <div>
    <template
      v-for="prop in filteredSimpleValues">
      <template v-if="!prop.isList">
        <v-text-field
          v-if="['integer', 'float'].includes(prop.type)"
          v-model="prop.value"
          :label="prop.username"
          :readonly="prop.isReadonly || readOnlyMode"
          dense
          outlined
          rows="1"
          type="number"
        />
        <v-textarea
          v-else
          v-model="prop.value"
          :label="prop.username"
          :readonly="prop.isReadonly || readOnlyMode"
          auto-grow
          dense
          outlined
          rows="1"
        />
      </template>
      <v-select
        v-if="prop.isList"
        v-model="prop.value"
        :items="prop.list"
        :label="prop.username"
        :readonly="prop.isReadonly || readOnlyMode"
        clearable
        dense
        item-text="value"
        item-value="code"
        outlined
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'ZwsFields',
  props: {
    feature: Object,
    searchField: {
      type: String,
      default: ''
    },
    readOnlyMode: false
  },
  computed: {
    simpleValues () {
      return this.feature.props.filter(it => {
        let type = it.name.split('::')[1].split('-')[0]
        if (it.type === 'memo') return false
        if (!['dphoto', 'darray', 'dhtml', 'dbloblist', 'durl'].includes(type)) return true
      })
    },
    filteredSimpleValues () {
      return this.simpleValues
        .filter(item => item.username.toLowerCase().indexOf(this.searchField.toLowerCase()) > -1)
    }
  }
}
</script>
