<template>
  <div style="width: 100%; position: relative">
    <v-text-field
      ref="numberHeaderFilter"
      :value="filter[getIndexOfFilter(header.alias)].fieldValue"
      class="mt-1"
      dense
      hide-details
      multiple
      outlined
      @focus="numbersMenu = true"
      @focusout="focusout"
      @input="changeFieldValue"
    >
      <template v-slot:append>
        <v-btn
          v-if="filter[getIndexOfFilter(header.alias)].fieldValue || filter[getIndexOfFilter(header.alias)].value[0]"
          elevation="0"
          icon
          small
          style="margin-top: -1px"
          @click="clear"
        >
          <v-icon color="primary">close</v-icon>
        </v-btn>
        <v-menu v-else>
          <template v-slot:activator="{on}">
            <v-btn class="pt-1" icon small v-on="on">
              <v-icon small>mdi-filter</v-icon>
            </v-btn>
          </template>
          <v-list v-if="header.predicates.length > 0">
            <v-list-item
              v-for="predicate in header.predicates"
              :style="{backgroundColor: filter[getIndexOfFilter(header.alias)].predicate === predicate ? '#EEEEEE' : 'white'}"
              class="ma-0"
              dense
              @click="filter[getIndexOfFilter(header.alias)].predicate = predicate"
            >
              {{ $t(predicate) }}
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:prepend-inner>
        <v-chip
          v-if="filter[getIndexOfFilter(header.alias)].value.length"
          small
        >
          {{ filter[getIndexOfFilter(header.alias)].value.length }}
        </v-chip>
      </template>
    </v-text-field>
    <div v-if="numbersMenu && equalsMode" class="et-number-filter-menu">
      <v-card class="pa-0 ma-0 number-header-menu-list d-flex flex-column" max-height="400">
        <v-card-text class="overflow-y-auto pa-0 ma-0">
          <v-list>
            <v-list-item class="number-menu-list-item" @click="changeSelectAll()">
              <v-list-item-action class="mr-1">
                <v-checkbox :input-value="selectAllValue"/>
              </v-list-item-action>
              <v-list-item-content>{{ $t('selectAll') }}</v-list-item-content>
            </v-list-item>
            <v-tooltip
              v-for="number in numberList"
              :key="number"
              bottom
              color="black"
              max-width="90vw"
              open-delay="1000"
            >
              <template v-slot:activator="{on}">
                <v-list-item
                  class="number-menu-list-item"
                  @click="changeSelectedNumber(number)"
                  v-on="on"
                >
                  <v-list-item-action class="mr-1">
                    <v-checkbox :input-value="!isNumberSelected(number)"/>
                  </v-list-item-action>
                  <v-list-item-content class="pa-0" style="text-align: start; max-height: 40px">
                    {{ number }}
                  </v-list-item-content>
                </v-list-item>
              </template>
              {{ number }}
            </v-tooltip>
          </v-list>
        </v-card-text>
        <v-card-actions class="elevation-1 ma-0 pa-0">
          <v-list-item class="number-menu-list-item" @click="changeIncludeEmpty()">
            <v-list-item-action class="mr-1">
              <v-checkbox :input-value="filter[getIndexOfFilter(header.alias)].includeEmpty"/>
            </v-list-item-action>
            <v-list-item-content>{{ $t('includeEmpty') }}</v-list-item-content>
          </v-list-item>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>


<script>
import messages from '@/componet-locale/et-filter/messages'
import { CONDITIONS } from '@/components/et/view-settings/utils'
import { EventBus } from '@/event-bus'

export default {
  name: 'NumberHeaderFilter',
  i18n: { messages },
  props: {
    header: Object,
    filter: Array,
    getIndexOfFilter: Function,
    tableId: Number
  },
  data: () => ({
    numberList: [],
    numbersMenu: false,
    autocompleteCancelTokenSource: null
  }),
  mounted () {
    this.updateNumberList()
    EventBus.$on('updateAutocompletes', this.updateNumberList)
  },
  methods: {
    focusout (e) {
      if (!!e.relatedTarget &&
        (e.relatedTarget.className.includes('number-menu-list-item')
          || e.relatedTarget.className.includes('number-header-menu-list'))
      ) {
        this.$refs.numberHeaderFilter.focus()
      } else {
        this.numbersMenu = false
      }
    },
    changeFieldValue (v) {
      this.filter[this.getIndexOfFilter(this.header.alias)].fieldValue = v
      if (!this.equalsMode) {
        this.filter[this.getIndexOfFilter(this.header.alias)].value = []
        this.filter[this.getIndexOfFilter(this.header.alias)].selectAll = true
      }
    },
    updateNumberList () {
      if (this.autocompleteCancelTokenSource) this.autocompleteCancelTokenSource.cancel()
      this.autocompleteCancelTokenSource = this.$axios.CancelToken.source()
      this.$axios
        .post(this.isAuthenticated ? 'et/query/autocomplete' : 'public-data/et-autocomplete', {
          tableId: this.tableId,
          header: this.header,
          value: this.filter[this.getIndexOfFilter(this.header.alias)].fieldValue || '',
          conditions: this.filter
        }, { cancelToken: this.autocompleteCancelTokenSource.token })
        .then(({ data }) => {
          this.numberList = data.map(it => it.value)
        })
        .catch(er => er ? console.error(er.message) : null)
    },
    changeSelectedNumber (number) {
      this.filter[this.getIndexOfFilter(this.header.alias)].value.includes(number)
        ? this.filter[this.getIndexOfFilter(this.header.alias)].value
          .splice(this.filter[this.getIndexOfFilter(this.header.alias)].value.indexOf(number), 1)
        : this.filter[this.getIndexOfFilter(this.header.alias)].value.push(number)

      if (this.filter[this.getIndexOfFilter(this.header.alias)].value.length === this.numberList.length
        && this.filter[this.getIndexOfFilter(this.header.alias)].selectAll
      ) {
        this.filter[this.getIndexOfFilter(this.header.alias)].value = []
        this.filter[this.getIndexOfFilter(this.header.alias)].selectAll = false
      }
    },
    clear () {
      this.filter[this.getIndexOfFilter(this.header.alias)].fieldValue = ''
      this.filter[this.getIndexOfFilter(this.header.alias)].value = []
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    isNumberSelected (number) {
      const isInValue = this.filter[this.getIndexOfFilter(this.header.alias)].value.includes(number)
      return isInValue === this.filter[this.getIndexOfFilter(this.header.alias)].selectAll
    },
    changeIncludeEmpty () {
      this.filter[this.getIndexOfFilter(this.header.alias)].includeEmpty
        = !this.filter[this.getIndexOfFilter(this.header.alias)].includeEmpty
    },
    changeSelectAll () {
      this.filter[this.getIndexOfFilter(this.header.alias)].selectAll = !this.selectAllValue
      this.filter[this.getIndexOfFilter(this.header.alias)].includeEmpty =
        this.filter[this.getIndexOfFilter(this.header.alias)].selectAll
      this.filter[this.getIndexOfFilter(this.header.alias)].value = []
    }
  },
  computed: {
    equalsMode () {
      return this.filter[this.getIndexOfFilter(this.header.alias)].predicate === CONDITIONS.EQUAL
    },
    selectAllValue () {
      if (this.filter[this.getIndexOfFilter(this.header.alias)].value.length === 0) {
        return this.filter[this.getIndexOfFilter(this.header.alias)].selectAll
      } else return this.filter[this.getIndexOfFilter(this.header.alias)].value.length === this.numberList.length
    }
  }
}
</script>

<style>
.et-number-filter-menu {
  position: absolute;
  top: 41px;
  left: 0;
  right: 0;
  min-width: 120px
}
</style>
