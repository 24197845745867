<template>
  <div>
    <div class="mb-4 mt-2">
      <div class="simulation-btn-group">
        <v-tooltip color="black" top>
          Запустить
          <template v-slot:activator="{on}">
            <v-btn
              :disabled="!featuresForSimulation.length"
              :loading="simulationLoading"
              icon
              outlined
              tile
              @click="simulate"
              v-on="{on}"
            >
              <v-icon>mdi-play</v-icon>
            </v-btn>
          </template>
        </v-tooltip>
        <v-tooltip color="black" top>
          Флаг
          <template v-slot:activator="{on}">
            <v-btn
              :disabled="!selectedFeatures.length"
              color="red"
              icon
              outlined
              tile
              @click="addFeatureForSimulation('flag')"
              v-on="on"
            >
              <v-icon>mdi-flag</v-icon>
            </v-btn>
          </template>
        </v-tooltip>
        <v-tooltip color="black" top>
          Включить
          <template v-slot:activator="{on}">
            <v-btn
              :disabled="!selectedFeatures.length"
              color="green"
              icon
              outlined
              tile
              @click="addFeatureForSimulation('circle')"
              v-on="on"
            >
              <v-icon>mdi-circle-outline</v-icon>
            </v-btn>
          </template>
        </v-tooltip>
        <v-tooltip color="black" top>
          Выключить
          <template v-slot:activator="{on}">
            <v-btn
              :disabled="!selectedFeatures.length"
              color="red"
              icon
              outlined
              tile
              @click="addFeatureForSimulation('cross')"
              v-on="on"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-tooltip>
        <v-tooltip color="black" top>
          Очистить
          <template v-slot:activator="{on}">
            <v-btn icon outlined tile @click="clearAll" v-on="on">
              <v-icon>mdi-eraser</v-icon>
            </v-btn>
          </template>
        </v-tooltip>
      </div>
      <v-select
        v-if="simulationType === 'layer-find-connected'"
        v-model="directionMode"
        :items="[{title: 'Без учёта направления', value: 'noDirection'},
        {title: 'По направлению ввода рёбер', value: 'straightDirection'},
        {title: 'Против направления ввода рёбер', value: 'reverseDirection'}]"
        class="mt-2"
        dense
        hide-details
        item-text="title"
        item-value="value"
        outlined
      />
      <v-checkbox
        v-model="ignoreState"
        class="mt-2"
        dense
        hide-details
        label="Игнорировать состояние"
      />
    </div>
    <div>
      <v-simple-table>
        <thead>
        <tr>
          <th>Ключ</th>
          <th>Тип</th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="feature in flagFeatures"
          style="cursor: pointer"
          @click="emitShowFeatureBySys(feature.elemId)"
        >
          <td>{{ feature.elemId }}</td>
          <td>{{ getFeatureType(feature) }}</td>
          <td>
            <v-btn icon @click.stop="removeFeatureFromSimulation(feature)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
    </div>

    <div class="mt-5">
      Включен
      <v-simple-table>
        <thead>
        <tr>
          <th>Ключ</th>
          <th>Тип</th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="feature in circleFeatures"
          style="cursor: pointer"
          @click="emitShowFeatureBySys(feature.elemId)"
        >
          <td>{{ feature.elemId }}</td>
          <td>{{ getFeatureType(feature) }}</td>
          <td>
            <v-btn icon @click.stop="removeFeatureFromSimulation(feature)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
    </div>

    <div class="mt-5">
      Выключен
      <v-simple-table>
        <thead>
        <tr>
          <th>Ключ</th>
          <th>Тип</th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="feature in crossFeatures"
          style="cursor: pointer"
          @click="emitShowFeatureBySys(feature.elemId)"
        >
          <td>{{ feature.elemId }}</td>
          <td>{{ getFeatureType(feature) }}</td>
          <td>
            <v-btn icon @click.stop="removeFeatureFromSimulation(feature)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
    </div>

    <div v-if="this.analyzedFeaturesIds.length" class="pl-3 py-3 font-weight-bold">
      В результате операции затронуто {{ this.analyzedFeaturesIds.length }} элементов.
      <v-progress-circular
        v-if="mapFeaturesLoading"
        :size="15"
        indeterminate
      />
    </div>
    <div>
      <portal to="simulation-layer">
        <vl-feature
          v-for="feature in featuresForSimulation"
          :properties="{obj: feature}"
        >
          <vl-geom-point
            v-if="feature.geometry"
            :coordinates="getFeatureForSimulationCoordinates(feature.geometry)"
          />
          <vl-style-box>
            <vl-style-icon
              v-if="feature.simulationType === 'flag'"
              :anchor="[0.2, 1]"
              :scale="0.07"
              src="/image/flag.png"
            />
            <vl-style-icon
              v-if="feature.simulationType === 'cross'"
              :anchor="[0.5, 0.5]"
              :scale="0.05"
              src="/image/cross.png"
            />
            <vl-style-circle v-if="feature.simulationType === 'circle'" :radius="10">
              <vl-style-stroke
                :width="4"
                color="#00FF00"
              />
            </vl-style-circle>
          </vl-style-box>
        </vl-feature>

        <vl-feature
          v-for="feature in changedFeatures"
          :properties="{obj: feature}"
        >
          <component
            :is="getComponent(feature.geometry.type)"
            :coordinates="feature.geometry.coordinates"
          />
          <vl-style-box>
            <vl-style-circle :radius="7">
              <vl-style-fill
                color="green"
              />
            </vl-style-circle>
            <vl-style-stroke
              :width="5"
              color="green"
            />
          </vl-style-box>
        </vl-feature>
      </portal>
    </div>
  </div>
</template>

<script>

import { EventBus } from '@/event-bus'
import zwsCommandBuilder from '@/services/zws-command-builder'
import _ from 'lodash'
import { LineString } from 'ol/geom'

export default {
  name: "LayerFindSimulationBar",
  props: {
    selectedFeatures: Array,
    simulationType: String
  },
  data: () => ({
    directionMode: 'noDirection',
    ignoreState: false,
    featuresForSimulation: [],
    analyzedFeaturesIds: [],
    changedFeatures: [],
    layer: null,
    simulationLoading: false,
    mapFeaturesLoading: false
  }),
  methods: {
    async simulate () {
      this.simulationLoading = true
      EventBus.$emit('clearSelectedFeatures')
      const layerFindSimulationDto = {
        flagIds: this.flagFeatures.map(it => it.elemId),
        circleIds: this.circleFeatures.map(it => it.elemId),
        crossIds: this.crossFeatures.map(it => it.elemId),
        ignoreState: this.ignoreState
      }
      zwsCommandBuilder.layerFindAnalyze(this.layer.layerId, layerFindSimulationDto, this.getLayerFindSimulationType())
        .then(res => {
          this.analyzedFeaturesIds = _.difference(res, this.featuresForSimulation.map(f => f.elemId))
          if (this.analyzedFeaturesIds.length === 0) {
            return EventBus.$emit('showInfoMessage', 'Анализ вернул пустой результат')
          }
          this.getChangedObjectsWithGeometry()
        })
        .catch(err => {
          console.error(err.message)
          EventBus.$emit('showErrorMessage', this.$t('error'))
        })
        .finally(() => this.simulationLoading = false)
    },
    addFeatureForSimulation (type) {
      this.analyzedFeaturesIds = []
      this.changedFeatures = []
      const featureForSimulation = JSON.parse(JSON.stringify(this.selectedFeatures[0]))
      if (featureForSimulation.layer.type !== 'ZWS') {
        return EventBus.$emit('showInfoMessage', 'Выбраны объекты неподдерживаемого слоя')
      }
      if (this.layer && this.layer.id !== this.selectedFeatures[0].layer.id) {
        EventBus.$emit('showInfoMessages', 'Выбран объект другого слоя')
      }
      if (!this.layer) {
        this.layer = featureForSimulation.layer
      }
      featureForSimulation.simulationType = type
      this.featuresForSimulation = this.featuresForSimulation.filter(f => f.elemId !== featureForSimulation.elemId)
      this.featuresForSimulation.push(featureForSimulation)
    },
    clearAll () {
      this.featuresForSimulation = []
      this.analyzedFeaturesIds = []
      this.changedFeatures = []
      this.layer = null
    },
    getChangedObjectsWithGeometry () {
      if (this.analyzedFeaturesIds.length > 2000) return console.error('More than 2000 features found')
      this.mapFeaturesLoading = true
      zwsCommandBuilder.getElemsByID(
        this.layer,
        this.analyzedFeaturesIds,
        null,
        {
          excludeProps: true,
          excludeModeList: true,
          excludeQueryList: true
        }
      )
        .then(data => this.changedFeatures = data)
        .finally(() => this.mapFeaturesLoading = false)
    },
    removeFeatureFromSimulation (feature) {
      this.featuresForSimulation = this.featuresForSimulation.filter(f => f.elemId !== feature.elemId)
    },
    emitShowFeatureBySys (sys) {
      EventBus.$emit('showFeature', this.layer, sys)
    },
    getComponent (geometryType) {
      switch (geometryType) {
        case 'Point' :
          return 'vl-geom-point'
        case 'LineString' :
          return 'vl-geom-line-string'
        case 'MultiLineString' :
          return 'vl-geom-multi-line-string'
        default:
          console.error('Unsupported geometry type: ' + geometryType)
      }
    },
    getFeatureForSimulationCoordinates (geometry) {
      switch (geometry.type) {
        case 'Point':
          return geometry.coordinates
        case 'LineString':
          return new LineString(geometry.coordinates).getCoordinateAt(0.5)
        default:
          console.error('Unsupported geometry type: ' + geometry.type)
      }
    },
    getLayerFindSimulationType () {
      switch (this.simulationType) {
        case 'layer-find-connected':
          switch (this.directionMode) {
            case 'noDirection':
              return 'LayerFindConnected'
            case 'straightDirection':
              return 'LayerFindConnectedDownstream'
            case 'reverseDirection':
              return 'LayerFindConnectedUpstream'
          }
          break
        case 'layer-find-disconnected':
          return 'LayerFindDisconnected'
        case 'layer-find-way':
          return 'LayerFindWay'
        case 'layer-find-loops':
          return 'LayerFindLoops'
      }
    },
    getFeatureType (feature) {
      return this.layer.typeList.find(t => t.typeId === feature.typeId).name
    }
  },
  computed: {
    flagFeatures () {
      return this.featuresForSimulation.filter(it => it.simulationType === 'flag')
    },
    circleFeatures () {
      return this.featuresForSimulation.filter(it => it.simulationType === 'circle')
    },
    crossFeatures () {
      return this.featuresForSimulation.filter(it => it.simulationType === 'cross')
    }
  }
}
</script>
