var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-4 mt-2"},[_c('div',{staticClass:"simulation-btn-group"},[_c('v-tooltip',{attrs:{"color":"black","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.featureForAnalyze,"loading":_vm.simulationLoading,"icon":"","outlined":"","tile":""},on:{"click":_vm.simulate}},on),[_c('v-icon',[_vm._v("mdi-play")])],1)]}}])},[_vm._v(" Запустить ")]),_c('v-tooltip',{attrs:{"color":"black","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.selectedFeatures.length,"icon":"","outlined":"","tile":""},on:{"click":_vm.addFeatureForSimulation}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_vm._v(" Добавить ")]),_c('v-tooltip',{attrs:{"color":"black","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.featureForAnalyze,"icon":"","outlined":"","tile":""},on:{"click":_vm.clearAll}},on),[_c('v-icon',[_vm._v("mdi-eraser")])],1)]}}])},[_vm._v(" Очистить ")])],1),_c('v-checkbox',{staticClass:"mt-2",attrs:{"dense":"","hide-details":"","label":"Полная изоляция"},model:{value:(_vm.isolation),callback:function ($$v) {_vm.isolation=$$v},expression:"isolation"}})],1),(_vm.featureForAnalyze)?_c('div',[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Ключ")])])]),_c('tbody',[_c('tr',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.emitShowFeatureBySys(_vm.getFeatureKey(_vm.featureForAnalyze))}}},[_c('td',[_vm._v(_vm._s(_vm.getFeatureKey(_vm.featureForAnalyze)))])])])])],1):_vm._e(),(_vm.analyzedFeaturesIds.length)?_c('div',[_c('div',{staticClass:"pl-3 py-3 font-weight-bold"},[_vm._v(" В результате операции затронуто "+_vm._s(_vm.analyzedFeaturesIds.length)+" элементов. ")]),_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Ключ")])])]),_c('tbody',_vm._l((_vm.analyzedFeaturesIds),function(id){return _c('tr',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.emitShowFeatureBySys(id)}}},[_c('td',[_vm._v(_vm._s(id))])])}),0)])],1):_vm._e(),_c('div',[_c('portal',{attrs:{"to":"simulation-layer"}},[(_vm.featureForAnalyze)?_c('vl-feature',{attrs:{"properties":{
        obj: _vm.featureForAnalyze
      }}},[(_vm.featureForAnalyze.geometry)?_c(_vm.getComponent(_vm.featureForAnalyze.geometry.type),{tag:"component",attrs:{"coordinates":_vm.featureForAnalyze.geometry.coordinates}}):_vm._e(),_c('vl-style-box',[_c('vl-style-circle',{attrs:{"radius":7}},[_c('vl-style-fill',{attrs:{"color":"yellow"}})],1),_c('vl-style-stroke',{attrs:{"width":5,"color":"yellow"}})],1)],1):_vm._e(),_vm._l((_vm.changedFeatures),function(feature){return _c('vl-feature',{attrs:{"properties":{
          obj: feature
        }}},[_c(_vm.getComponent(feature.type),{tag:"component",attrs:{"coordinates":feature.type === 'Point' ? feature.coordinates[0] : feature.coordinates}}),_c('vl-style-box',[_c('vl-style-circle',{attrs:{"radius":7}},[_c('vl-style-fill',{attrs:{"color":"purple"}})],1),_c('vl-style-stroke',{attrs:{"width":5,"color":"purple"}})],1)],1)})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }