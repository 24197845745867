import { EventBus } from '@/event-bus'
import zwsCommandBuilder from '@/services/zws-command-builder'

export const mapSimulationMixin = {
  props: {
    map: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    changedFeatures: [],
    relatedFeaturesLoading: false
  }),
  methods: {
    async showRelatedFeatures (relation) {
      const sourceFeatures = this.changedFeatures
        .filter(el => el.layer.id === relation.sourceLayer.id)
      if (sourceFeatures.length === 0) return EventBus.$emit('showInfoMessage', 'Нет подходящих объектов')

      this.relatedFeaturesLoading = true
      const sourceFeaturesWithProps = await zwsCommandBuilder.getElemsByID(relation.sourceLayer, sourceFeatures.map(it => it.elemId), null, {
        excludeModeList: true,
        excludeQueryList: true,
        excludeGeometry: true
      })

      const relatedFeaturesIds = sourceFeaturesWithProps
        .map(f => {
          const prop = f.props.find(prop => prop.name === relation.relationPropertyName)
          return prop ? prop.value : null
        })
        .filter(el => el)

      if (relatedFeaturesIds.length === 0) {
        this.relatedFeaturesLoading = false
        return EventBus.$emit('showInfoMessage', 'Нет связанных объектов')
      }
      await zwsCommandBuilder.getLayerTypes(relation.targetLayer)
      const relatedFeatures = await zwsCommandBuilder.getElemsByID(relation.targetLayer, relatedFeaturesIds, null, {
        excludeGeometry: false,
        excludeQueryList: true,
        excludeModeList: true,
        excludeProps: true
      }).finally(() => this.relatedFeaturesLoading = false)
      EventBus.$emit('selectFeatures', relatedFeatures)
    }
  }
}
